<template>
  <div class="case-study-con">
    <vue-element-loading :active="loading" is-full-screen>
      <img src="@/assets/gif/Motion-Logo-.gif" style="width: 50%; max-width: 250px;">
    </vue-element-loading>
    <div class="caseStudy" v-if="caseStud === true">
      <!-- <img src="@/assets/case/Path 333-min.png" alt="track" id="belokan" /> -->
      <Navbar @lang="getLang" color="white" />
      <div style="display: none">
        Kintaro Sushi Restoran Jepang yang menyajikan sushi gulung, sashimi & berbagai varian mie secara kontemporer.
        Feedback Meningkatkan awareness lewat promosi harian Secara otomatis mengirimkan email untuk pelanggan.
        Devon Cafe Interior minimalis dan bergaya kasual yang mampu menghidupkan mood kamu untuk menikmati sajian brunch dengan nikmat.
        LA Light Menargetkan campaign ditonton oleh audien yang memiliki usia 18-34 tahun, campaign LA ICE ditayangkan di beberapa lokasi yang sering dikunjungi oleh audien dengan rentang usia tersebut.
        21 Factory Premium gelato yang dibuat dengan resep Italia nan otentik lengkap dengan bahan berkualitas spesial untuk harimu.
        Walking Drums Menarik perhatian dengan suasana yang nyaman dan menjadikannya tempat ideal untuk bersantai.
        Abbot Memiliki 3 produk dengan segmentasi yang berbeda-beda, HIGO membuat strategi campaign lewat satu survei. Menargetkan wanita yang sudah memiliki anak, setiap wanita yang terkoneksi WiFi akan mendapat pertanyaan tentang "Jumlah Anak". Setiap audien yang telah menyelesaikan survei akan dianggap menjadi satu lead.
        Home Credit Strategi yang dilakukan untuk meningkatkan awareness terhadap produk Home Credit, HIGO membuat permainan dengan judul Memorable Game. Game ini berisi beberapa produk pilihan yang bisa dicicil dengan syarat dan ketentuan yang mudah.
        Kintaro Sushi Restoran Jepang yang menyajikan sushi gulung, sashimi & berbagai varian mie secara kontemporer.
        Feedback Meningkatkan awareness lewat promosi harian Secara otomatis mengirimkan email untuk pelanggan.
        Devon Cafe Interior minimalis dan bergaya kasual yang mampu menghidupkan mood kamu untuk menikmati sajian brunch dengan nikmat.
        LA Light Menargetkan campaign ditonton oleh audien yang memiliki usia 18-34 tahun, campaign LA ICE ditayangkan di beberapa lokasi yang sering dikunjungi oleh audien dengan rentang usia tersebut.
        21 Factory Premium gelato yang dibuat dengan resep Italia nan otentik lengkap dengan bahan berkualitas spesial untuk harimu.
        Walking Drums Menarik perhatian dengan suasana yang nyaman dan menjadikannya tempat ideal untuk bersantai.
        Abbot Memiliki 3 produk dengan segmentasi yang berbeda-beda, HIGO membuat strategi campaign lewat satu survei. Menargetkan wanita yang sudah memiliki anak, setiap wanita yang terkoneksi WiFi akan mendapat pertanyaan tentang "Jumlah Anak". Setiap audien yang telah menyelesaikan survei akan dianggap menjadi satu lead.
        Home Credit Strategi yang dilakukan untuk meningkatkan awareness terhadap produk Home Credit, HIGO membuat permainan dengan judul Memorable Game. Game ini berisi beberapa produk pilihan yang bisa dicicil dengan syarat dan ketentuan yang mudah.
      </div>
      <div class="landing-case">

        <div id="content-container">
           <img @load="imgLoad" src="@/assets/case/5DA657A-min.png" alt="tangan-komputer" id="tangan-komputer" />
          <div id="content-wrapper">
           <div id="text-content" v-if="lang === 'id'">
             <h1>Studi<br/>Kasus</h1>
             <h4>Kisah sukses yang seneng untuk dibagi-bagi</h4>
           </div>

           <div id="text-content" v-else>
             <!-- because duplicate title tag (SEO) -->
             <h6>Case<br/>Study</h6>
             <h4>Here are some good stories for you</h4>
           </div>
           <div id="select-content">
              <button @click.prevent="cases = 'merchant'" class="pick-cases" :class="[cases === 'merchant' ? 'cases-picked' : null]"><h4>Merchant</h4></button>
              <button @click.prevent="cases = 'campaign'" class="pick-cases" :class="[cases === 'campaign' ? 'cases-picked' : null]"><h4>Campaign</h4></button>
           </div>
          </div>
        </div>
        <div id="selection-container">
          <div id="list-cases1" :style="{display: cases === 'campaign' ? 'grid' : 'none'}">
            <img src="@/assets/case/Group 373.png" @click.prevent="$router.push('/case-study/la')" alt="" class="case campaign-case open-1">
            <img src="@/assets/case/Group 372.png" @click.prevent="$router.push('/case-study/abbott')" alt="" class="case campagin-case open-2">
            <img src="@/assets/case/Group 374.png" @click.prevent="$router.push('/case-study/home-credit')" alt="" class="case campaign-case open-3">
          </div>
          <div id="list-cases2" :style="{display: cases === 'merchant' ? 'grid' : 'none'}">
            <img @load="imgLoad" src="@/assets/case/Group 320.png" @click.prevent="$router.push('/case-study/kintaro-sushi')" alt="" class="case merchant-case open-1">
            <img @load="imgLoad" src="@/assets/case/Group 365.png" @click.prevent="$router.push('/case-study/devon')" alt="" class="case merchant-case open-2">
            <img @load="imgLoad" src="@/assets/case/Group 367.png" @click.prevent="$router.push('/case-study/21-factory')" alt="" class="case merchant-case open-3">
            <img @load="imgLoad" src="@/assets/case/Group 366.png" @click.prevent="$router.push('/case-study/walking-drums')" alt="" class="case merchant-case open-4">
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Navbar,
    Footer
  },
  data: function () {
    return {
      caseStud: true,
      factory: false,
      from: null,
      cases: 'merchant',
      loading: true,
      imgCount: 5,
      lang: ''
    }
  },
  metaInfo: {
    title: 'HIGO | Studi kasus',
    meta: [
      {
        name: 'description',
        content:
          'Cerita sukses lokasi partner HIGOspot dan brand yang sudah beriklan di HIGO.'
      },
      {
        name: 'keywords',
        content:
          'Cerita sukses lokasi partner HIGOspot, Brand yang sudah beriklan, Campaign di lokasi HIGOspot'
      }
    ]
  },
  methods: {
    imgLoad () {
      this.imgCount--

      if (this.imgCount) {
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    getLang (value) {
      this.lang = value
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 550)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.from = from
    })
  },
  created () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
@keyframes grow {
  0% {
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.open-1, .open-1b, .close-1 {
  transform: translate3d(62%, 58%, .5rem);
}

.open-2, .open-2b, .close-2 {
  transform: translate3d(-62%, 58%, .5rem);
}

.open-3, .open-3b,.close-3 {
  transform: translate3d(62%, -58%, .5rem);
}

.open-4, .open-4b, .close-4 {
  transform: translate3d(-62%, -58%, .5rem);
}

/* .open-1, .open-2, .open-3, .open-4 {
  opacity: 0;
} */

.caseStudy {
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  min-height: 100vh;
}

.case-content {
  min-height: 100vh;
  height: auto;
}

#belokan,
#tangan-komputer {
  position: absolute;
}

#belokan {
  top: -4rem;
  width: 55%;
  right: -5rem;
}

#tangan-komputer {
  width: 85%;
  bottom: 0;
  left: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.landing-case {
  min-height: 100vh;
  padding-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

#content-container {
  position: relative;
}

#content-container::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: .87;
  z-index: 1;
}

#content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#text-content {
  color: #0245ab;
}

#text-content h1, h6 {
  font-size: 6vw;
  font-weight: 800;
  line-height: 1;
}

#text-content h4 {
  font-size: 1.5vw;
  margin-top: 10px;
}

#content-wrapper #select-content {
  width: 100%;
  height: 2.7rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pick-cases {
  border: none;
  background: #fff;
  color: #0245ab;
  width: 10rem;
  height: 100%;
  margin: 0 2.5rem;
  display: grid;
  place-items: center;
  border-radius: 25px;
  cursor: pointer;
  transition: .3s ease-out;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07),
                0 2px 4px rgba(0,0,0,0.07),
                0 4px 8px rgba(0,0,0,0.07),
                0 8px 16px rgba(0,0,0,0.07),
                0 16px 32px rgba(0,0,0,0.07),
                0 32px 64px rgba(0,0,0,0.07);
}

.pick-cases h4 {
  font-size: 1.3em;
}

.pick-cases:focus {
  outline: none;
}

.cases-picked, .pick-cases:hover {
  color: white;
  background: rgb(0,141,204);
  background: -webkit-gradient(linear, left top, right top, from(rgba(0,141,204,1)), color-stop(63%, rgba(1,205,232,1)));
  background: -o-linear-gradient(left, rgba(0,141,204,1) 0%, rgba(1,205,232,1) 63%);
  background: linear-gradient(90deg, rgba(0,141,204,1) 0%, rgba(1,205,232,1) 63%);
}

#selection-container {
  background: rgb(0,141,204);
  background: linear-gradient(149deg, rgba(0,141,204,1) 19%, rgba(1,205,232,1) 93%);
}

#list-cases1, #list-cases2 {
  width: 100%;
  height: 100%;
  display: none;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 10%;
}

img.case {
  width: 80%;
  height: auto;
  cursor: pointer;
  animation: 1s forwards grow;
  animation-timing-function: cubic-bezier(.51,.92,.24,1.15);
}

.cardCase {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  height: calc(100vh - 15vh);
  margin: 0 auto;
}

.cardCase .case-places {
  width: 100%;
  height: auto;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;

  transition: transform 0.25s ease-in-out;
  -moz-transition: transform 0.25s ease-in-out;
  -webkit-transition: transform 0.25s ease-in-out;
}

.cardCase .case-places:hover {
  opacity: 0.4;
  transform: scale(1.2);
  cursor: pointer;
}

.bgBlue1:hover > #ellipse-hover,
.bgBlue2:hover > #ellipse-hover,
.bgBlue3:hover > #ellipse-hover,
.bgBlue4:hover > #ellipse-hover {
  opacity: 1;
}

.bgBlue1 {
  grid-column: 1;
  grid-row: 1;
  border-radius: 22px;
  background-color: #1397d0;
  overflow: hidden;
  position: relative;
}

.bgBlue2 {
  grid-column: 2;
  grid-row: 1;
  border-radius: 22px;
  background-color: #1397d0;
  overflow: hidden;
  position: relative;
}

.bgBlue3 {
  grid-column: 1;
  grid-row: 2;
  border-radius: 22px;
  background-color: #1397d0;
  overflow: hidden;
  position: relative;
}

.bgBlue4 {
  grid-column: 2;
  grid-row: 2;
  border-radius: 22px;
  background-color: #1397d0;
  overflow: hidden;
  position: relative;
}

/* .case-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 22px;
  background-color: #1397d0;
  opacity: 0;
  transition: 0.3s;
} */

#ellipse-hover {
  position: absolute;
  width: 50%;
  height: auto;
  top: 25%;
  left: 25%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  z-index: 10;
}

#ellipse-hover:hover {
  opacity: 1;
  cursor: pointer;
}

#ellipse-hover:hover ~ .case-places {
  opacity: 0.4;
  transform: scale(1.2);
  cursor: pointer;
}

/*
#ellipse-hover:hover {
  opacity: 1;
}

#ellipse-hover:hover ~ .case-hover {
  opacity: 0.6;
}

.case-hover:hover ~ #ellipse-hover {
  opacity: 1;
}

.case-hover:hover {
  opacity: 0.6;
} */

@media screen and (min-width: 1200px) {
  .landing-case {
    width: 100%;
  }

  .cardCase {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .landing-case {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(250px, .5fr) 1fr;
  }

  #text-content {
    padding: 0 5%;
  }

  #text-content h1 {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1;
}

  #text-content h4 {
    font-size: .9rem;
    margin-top: 10px;
  }

  #content-wrapper #select-content {
    margin-top: 2rem;
  }

  .pick-cases {
    margin: 0 1rem;
  }

  #list-cases1, #list-cases2 {
    padding: 5%;
  }
}
</style>
